<!--
  @USE AS:
  import Accordion from '@/components/base/Accordion'

  components: { Accordion }

  <accordion title="test" >
    <p>Lorem ipsum dolor sit amet..</p>
  </accordion>
-->

<template>
<div class="accordion__items" :class="toggle" ref="accordion" @click="toggleAccordion">
  <!-- .accordion__items -->
  <div class="accordion__title" ref="accordionTitle">
    <!-- .accordion__title -->
    <span>
        {{ title }}
      </span>

    <div class="accordion-arr">
      <i class="fas fa-angle-up"></i>
    </div>
  </div> <!-- // .accordion__title -->

  <transition name="expand">
    <div class="accordion__con" ref="accordionCon" v-if="active">
      <!-- .accordion__con -->
      <slot></slot>
    </div> <!-- // .accordion__con -->
  </transition>
</div> <!-- // .accordion__items -->
</template>

<script>
export default {

  props: {
    title: [String],
    content: [String]
  },

  data () {
    return {
      active: true,
      toggle: ''
    }
  },

  watch: {
    active () {
      let toggle

      if (this.active == false) toggle = ''
      else toggle = 'is-open'

      return this.toggle = toggle
    }
  },

  created () {},

  methods: {
    toggleAccordion (e) {
      let self = this
      console.log("crash",this.$refs.accordionTitle, e.target);
      if (e.target === this.$refs.accordionTitle) {
        this.active = !this.active

        let num = Math.random()
        self.$emit('trigger', num)
      }
    }
  }
}
</script>
